// Parameters
export const apiUrl = 'https://customers-service.commusoft.co.uk/';
export const adminApiUrl = 'https://admin-api.commusoft.co.uk/adminwebservice_dev.php/admin/api/v1/';
export const jwtTokenKey= 'h9f09b8e-d3ca-r246-tce9-q239d852a6dr';
export const redirectToAfterLogin = "/property/list";
export const priority = [''];
export const jobStatus = {
    'Invoiced': 'Invoiced',
    'FreeOfCharge': "Free of charge",
    'Aborted': 'Aborted',
    'ongoing': 'Ongoing',
    'completed': 'Completed',
    'Accepted': 'Accepted',
    'Rejected': 'Rejected',
    'accepted': 'Accepted',
    'rejected': 'Rejected',
    'waiting_for_customer': 'Waiting on customer',
    'reserved': 'Reserved'
};

export const diaryStatus = {
     'just_booked': 'Just booked',
     'accepted': "Accepted",
     'rejected': "Rejected",
     'travel': "Traveling to site",
     'arrived': "Arrive",
     'no_access': "No access",
     'aborted': 'Aborted',
     'left': 'Left site',
     'cancelled': 'Cancelled',
     'completed': 'Completed'

};

export const jobPriority = {
    'NOT_IMPORTANT': 'Not_Important',
    'MEDIUM_IMPORTANCE': 'Medium Importance',
    'IMPORTANT': 'Important',
    'URGENT': 'Urgent',
};


export const filesExt = ['mp3','m4a','ogg','mid','avi','flv','mov','mp4','mpg','mpeg','7z','rar','gz','tar.gz','zip','csv','xml','gif','jpeg','jpg','png','odp','pps','ppt','pptx','ods','xlsx','xls','doc','docx','pdf','txt','odt','key','numbers','pages'];

export const previewImage = ['gif','jpeg','jpg','png'];
export const appKey = "wert346w-we23-er45-34r5-t6y7u7124567t";

export const features = {
    1: 'view property',
    2: 'View job/estimate',
    3: 'view invoice',
    4: 'view diaries',
    5: 'view certificate',
    6: 'view user',
    7: 'add user',
    8: 'edit user',
    9: 'delete user',
}

export const featureMap = {
    '/diaryevent/list': 4,
    '/property/list': 1,
    '/invoice/list': 3,
    '/certificate/list': 5,
    '/users/list': 6,
    '/property/view': 2,
    '/job/view': 2,
    '/estimate/view': 2,
    '/add/job': 10,
    '/add/property': 11,
    '/add/user': 7,
    '/edit/user': 8,
    '/delete/user': 9,
    '/': 1,
}
export const adminAPIKey = "ca70b11c-f353-4b4e-9366-8834816a5649";
